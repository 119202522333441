import Button from '@/components/buttons/Button'
import Ellipsis from '@/components/ellipsis'
import _get from 'lodash/get'
import { parseDate } from '@/utilities/Date/parse'
import parseAddress from '@/utilities/address/parse'
import { workOrderRequestStatus } from '@/utilities/workOrders/request/status'
import { workOrderList } from '@/services/WorkOrders/store'
import { getWorkOrderReqsList } from '@/services/WorkOrderRequests/WorkOrderRequest/List'
import { notifyProblem } from '@/services/notify'
import moment from 'moment'

export const methods = {
  confirmDeleteWorkOrder(id, description) {
    this.$buefy.dialog.confirm({
      title: 'Deleting Work Order',
      message: `Are you sure you want to <b>delete</b> the Work Order for "${description}"?`,
      confirmText: 'Delete Work Order',
      type: 'is-danger',
      hasIcon: true,
      onConfirm: () => this.deleteWorkOrder(id)
    })
  },

  async confirmCancelWorkOrder(id, description) {
    this.$buefy.dialog.prompt({
      message: `Are you sure you want to <b>cancel</b> this work order for: "${description}"?`,
      inputAttrs: {
        placeHolder: 'Cancellation Reason',
        maxlength: 8000
      },
      confirmText: 'Cancel Work Order',
      cancelText: 'Close',
      trapFocus: true,
      onConfirm: value => this.cancelWorkOrder(id, value)
    })
  },

  confirmTransmitWorkOrderNotification(id, description) {
    this.$buefy.dialog.confirm({
      title: 'Transmitting Work Order',
      message: `Are you sure you want to <b>transmit</b> a Work Order Notification for "${description}"?`,
      confirmText: 'Transmit Work Order',
      type: 'is-danger',
      hasIcon: true,
      onConfirm: () => this.ellipsisClicked(id)
    })
  },

  determineRows: function(list) {
    const dateFormat = 'LL'

    var rows = list
      .map(entry => {
        const dateCreated = parseDate(_get(entry, 'createdDate', null), dateFormat)
        const sentToVendorDate = _get(entry, 'sentToVendorDate', null)
        const tempDateCompleted = _get(
          entry,
          'completionDate',
          moment('01/01/1900').format(dateFormat)
        )
        const dateCompleted = tempDateCompleted
          ? parseDate(tempDateCompleted, dateFormat)
          : moment('01/01/1900').format(dateFormat)
        const tempDateCancelled = _get(
          entry,
          'cancelledDate',
          moment('01/01/1900').format(dateFormat)
        )
        const dateCancelled = tempDateCancelled
          ? parseDate(tempDateCancelled, dateFormat)
          : moment('01/01/1900').format(dateFormat)
        const id = _get(entry, 'workOrderID', null)
        const description = _get(entry, 'description', null)
        const type = _get(entry, 'workOrderTypeName')
        const vendor = _get(entry, 'vendorName', null)

        return {
          id,
          type: type,
          vendorName: vendor,
          description: description,
          address: _get(entry, 'locationDescription', ''),
          dateCreated: {
            date: dateCreated,
            format: dateFormat
          },
          dateCancelled: {
            date: dateCancelled,
            format: dateFormat
          },
          dateCompleted: {
            date: dateCompleted,
            format: dateFormat
          },
          edit: {
            component: Button,
            props: {
              onClick: () => {
                this.processWorkOrderUpdate(entry)
              },
              text: 'Details'
            }
          },
          ellipsis: {
            component: Ellipsis,
            props: {
              onAskQuestionClick: () => {
                this.redirectToAskaQuestion(id, _get(entry, 'vendorID', null), vendor)
              },
              onDeleteClick: () => {
                this.confirmDeleteWorkOrder(id, description)
              },
              onCancelClick: () => {
                this.confirmCancelWorkOrder(id, description)
              },
              onTransmitClick: () => {
                this.confirmTransmitWorkOrderNotification(id, description)
              },

              text: 'Ellipsis',
              isDeleteDisabled: sentToVendorDate !== undefined && sentToVendorDate ? true : false,
              isCancelledDisabled:
                tempDateCompleted !== undefined && tempDateCompleted ? true : false
            }
          }
        }
      })
      .filter(row => {
        if (this.statusFilter === 'complete') {
          return _get(row, 'dateCompleted.date', null) !== null
        } else if (this.statusFilter === 'incomplete') {
          return (
            _get(row, 'dateCompleted.date', null) === null &&
            _get(row, 'dateCancelled.date', null) === null
          )
        } else if (this.statusFilter === 'cancelled') {
          return _get(row, 'dateCancelled.date', null) !== null
        } else {
          return true
        }
      })

    this.rows = rows
  },
  async ellipsisClicked(id) {
    console.debug('ellipsisClicked...')

    if (id) {
      await workOrderList.dispatch('transmitNotification', {
        workOrderID: id,
        done: ({ details }) => {
          console.debug('details=' + details)
          this.reload()
          this.loading = false
        }
      })
    }
  },

  async reload() {
    console.debug('in reload...')
    this.loading = true

    const loadingComponent = this.$buefy.loading.open({
      container: null
    })

    const loadRequests = this.$route.params.loadrequests

    await workOrderList
      .dispatch('getWorkOrderList', {
        hoaId: this.hoaId
      })
      .then(({ list }) => {
        const openWorkOrderCount = list.filter(f => f.completionDate == null).length
        this.openWorkOrderCount = openWorkOrderCount ? openWorkOrderCount : 0
        this.determineRows(list)
        this.loading = false
      })

    loadingComponent.close()

    await this.determineWorkOrderRequests()

    if (loadRequests === true) {
      this.statusFilter = 'requests'
      this.$route.params.loadrequests = null
    }

    if (this.statusFilter === 'requests') {
      this.requestsFilter = 'pending'
    }

    this.loading = false
  },

  filterRequestRows() {
    const requestsFilter = this.requestsFilter.toLowerCase()

    this.rowsTwoFiltered = this.rowsTwo.filter(row => {
      const status = _get(row, ['status', 'props', 'text'], '').toLowerCase()

      if (requestsFilter === 'pending') {
        return status === 'pending'
      } else if (requestsFilter === 'promoted') {
        return status === 'promoted'
      } else if (requestsFilter === 'cancelled') {
        return status === 'cancelled'
      } else if (requestsFilter === 'rejected') {
        return status === 'rejected'
      } else if (requestsFilter === 'all') {
        return true
      }

      return status === 'pending'
    })
  },

  async determineWorkOrderRequests() {
    const loadingComponent = this.$buefy.loading.open({
      container: null
    })

    const { list, message, successful } = await getWorkOrderReqsList({
      params: {
        hoaId: this.hoaId
      }
    })
    if (!successful) {
      notifyProblem(message)
      return
    }

    const openWorkOrderRequestList = list.filter(f => f.completedDate == null)
    this.openWorkOrderRequestsCount = openWorkOrderRequestList.length

    this.rowsTwo = []
    for (let a = 0; a < list.length; a++) {
      const entry = list[a]

      const parsedAddress = parseAddress({
        address: _get(entry, 'sourceUnit.address', null)
      })

      const submitterOwnerID = _get(entry, 'sourceUnit.primaryOwnerID', 0)
      const submittedBy = `Add Homeowner - ${_get(entry, 'submitterName', '')}`

      const statusText = _get(entry, 'status', '')
      const { status } = workOrderRequestStatus({ statusText })

      this.rowsTwo.push({
        description: _get(entry, 'description', ''),
        detailedDescription: _get(entry, 'detailedDescription', ''),
        status,
        submittedByAddress: parsedAddress,
        submittedBy: submittedBy,
        createdDate: parseDate(_get(entry, 'createdDate', ''), 'LL'),
        details: {
          component: Button,
          props: {
            onClick: () => {
              this.redirectToWorkOrderRequestDetails(_get(entry, 'workOrderRequestID', 0))
            },
            text: 'Details'
          }
        },
        askquestion: {
          component: Button,
          props: {
            onClick: () => {
              this.redirectToAskaQuestionFromRequest(
                _get(entry, 'workOrderRequestID', 0),
                submitterOwnerID,
                submittedBy
              )
            },
            text: 'Ask a Question'
          }
        }
      })

      this.filterRequestRows()
    }

    loadingComponent.close()
  },

  redirectToAskaQuestion(id, vendorId, vendorLabel) {
    this.$router.push({
      name: 'newconversation',
      params: {
        workorderid: id,
        workordervendorid: vendorId,
        workorderlabel: `Add Vendor - ${vendorLabel || ''}`
      }
    })
  },

  redirectToAskaQuestionFromRequest(id, ownerId, label) {
    this.$router.push({
      name: 'newconversation',
      params: {
        workorderrequestid: id,
        submitterid: ownerId,
        submitterlabel: label
      }
    })
  },

  redirectToWorkOrderRequestDetails(id) {
    if (id > 0) {
      this.$router.push({
        path: `/workorderpromotetabs/${id}/workorderRequestPromote#tab`,
        params: {
          id: id
        }
      })
    }
  },

  processWorkOrderUpdate(workOrder) {
    this.toggle = true
    this.selectedWorkOrder = workOrder

    const updatePath = `/workordertabs/${workOrder.workOrderID}/workorderDetails#tab`

    this.$router.push(updatePath)
  },

  createWorkOrderModal() {
    this.toggle = true
  },

  async cancelWorkOrder(id, cancelReason) {
    if (!cancelReason) {
      notifyProblem('Please enter a reason for cancelling this work order.')
      return
    }

    try {
      await workOrderList.dispatch('cancelWorkOrder', {
        workOrderID: id,
        cancelledReason: cancelReason,
        done: () => {
          this.loading = false
          this.reload()
        }
      })
    } catch (e) {
      if (this.isDebug == true) console.debug(e)
      console.debug('There was a problem cancelling this work order.')
    }
  },

  async deleteWorkOrder(id) {
    await workOrderList.dispatch('deleteWorkOrder', {
      workOrderID: id,
      done: () => {
        this.loading = false
        this.reload()
      }
    })
  }
}
